angular.module("aerosApp")
    .factory('addLicenseModal', ["$uibModal", function ($uibModal) {
        function open(orgInfo) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    orgInfo: function () {
                        return orgInfo;
                    }
                },
                templateUrl: "/static/templates/project/addLicenseModal.html",
                controller: "AddLicenseModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("AddLicenseModalCtrl", ["$scope", "$uibModalInstance", "aerosApi", "Notification", "orgInfo", function ($scope, $uibModalInstance, aerosApi, Notification, orgInfo) {
        $scope.newLicenseModel = {
            key: ""
        };

        function checkUuid(licenseKey) {
            return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(licenseKey);
        }

        $scope.addLicense = function () {
            if (checkUuid($scope.newLicenseModel.key)) {
                aerosApi.addLicenseToOrganization(orgInfo.id, $scope.newLicenseModel.key)
                    .success(function () {
                        $uibModalInstance.close();
                        Notification.success("License (" + $scope.newLicenseModel.key + ") has been added to " + orgInfo.name);
                    })
                    .error(function (err) {
                        Notification.error("License not added. " + err.message);
                    });
            } else {
                Notification.error("License not added, wrong format.");
            }
        };
    }]);